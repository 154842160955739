import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  mainBlue,
  mainWhite,
  mainBlack,
  mainGreen,
  mainBrown,
  screen,
} from "../components/variables";
import gsap from "gsap";
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiFillFacebook,
  AiFillYoutube,
  AiFillTwitterSquare,
} from "react-icons/ai";
import Obfuscate from "react-obfuscate";
import ContactForm from "../components/contact-form";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${mainBlue};
  color: ${mainWhite};
  min-height: 100vh;
  @media ${screen.xsmall} {
    min-height: 100vh;
  }

  .inner-wrapper--contact {
    visibility: hidden;
  }

  .intro {
    padding: 180px 0 0 0;
    max-width: 935px;
    @media ${screen.xsmall} {
      padding: 220px 0 0 0;
    }
    @media ${screen.large} {
      padding: 235px 0 0 0;
    }

    @media ${screen.xlarge} {
      padding: 265px 0 0 0;
    }

    .heading {
      font-weight: 700;
      font-size: 1.9rem;
      @media ${screen.xsmall} {
        font-size: 2.2rem;
      }
      @media ${screen.medium} {
        font-size: 2.4rem;
      }
    }

    .description {
      margin: 20px 0 35px 0;
      max-width: 290px;
      @media ${screen.xsmall} {
        max-width: 490px;
        margin: 33px 0 46px 0;
      }
      @media ${screen.medium} {
        max-width: 580px;
      }

      p {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.35;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.6rem;
        }
        @media ${screen.medium} {
          font-size: 1.88rem;
        }
      }
    }
  }

  .email {
    color: ${mainWhite};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.35;
    border-bottom: 1px ${mainWhite} solid;
    @media ${screen.xsmall} {
      font-size: 1.6rem;
      border-bottom: 2px ${mainWhite} solid;
    }
    @media ${screen.medium} {
      font-size: 1.88rem;
    }

    @media ${screen.withCursor} {
      &:hover {
        background: ${mainWhite};
        color: ${mainBlue};
      }
    }
  }

  .contact-social {
    margin: 80px 0 0 0;
    @media ${screen.xsmall} {
      margin: 46px 0 0 0;
    }

    h6 {
      font-size: 1.35rem;
      @media ${screen.xsmall} {
        font-size: 1.5rem;
      }
      @media ${screen.medium} {
        font-size: 1.65rem;
      }
    }

    .social-list {
      display: flex;
      margin: 20px 0 0 0;

      &__each {
        a {
          color: ${mainGreen};
          font-size: 2.5rem;
          margin: 0 16px 0 0;
          @media ${screen.xsmall} {
            margin: 0 20px 0 0;
            font-size: 3rem;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainWhite};
            }
          }
        }
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  const {
    heading,
    description,
    email,
    title_tag,
    meta_description,
    social_list,
  } = data.content.data;

  useEffect(() => {
    // animate intro
    const intro = gsap.timeline();

    intro
      .from(".inner-wrapper--contact", { autoAlpha: 0 })
      .from(".intro .heading", { opacity: 0, y: 5 })
      .from(".intro .description", { opacity: 0, y: 5 })
      .from(".email", { opacity: 0, y: 5 })
      .from(".contact-social", { opacity: 0, y: 5 });
  }, []);

  return (
    <Layout isContactPage={true}>
      <SEO
        title={title_tag}
        description={meta_description}
        webPageInfo={{
          name: title_tag,
          url: "https://www.fmondaysmarketing.com/contact/",
          description: meta_description,
        }}
      />
      <Wrapper>
        <div className="inner-wrapper inner-wrapper--contact">
          <header className="intro">
            <h1 className="heading">{heading}</h1>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description.html }}
            />
          </header>
          <Obfuscate className="email" email={email} />

          <section className="contact-social">
            <h6>Stay connected</h6>

            <ul className="social-list">
              {social_list.map((item) => (
                <li className="social-list__each" key={item.platform}>
                  <a
                    href={item.full_url}
                    target="_blank"
                    aria-label="Visit social platform"
                    rel="noopener"
                  >
                    {item.platform === "LinkedIn" && <AiFillLinkedin />}
                    {item.platform === "Facebook" && <AiFillFacebook />}
                    {item.platform === "Twitter" && <AiFillTwitterSquare />}
                    {item.platform === "Instagram" && <AiOutlineInstagram />}
                    {item.platform === "Youtube" && <AiFillYoutube />}
                  </a>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ContactPage;

export const dataQuery = graphql`
  {
    content: prismicContactPage {
      data {
        heading
        description {
          html
        }
        email
        social_heading
        social_list {
          platform
          full_url
        }
        title_tag
        meta_description
      }
    }
  }
`;
